<template>
  <div>
    <div class="flex-display">
      <el-input
        placeholder="请输入内容"
        v-model="input"
        class="input-with-select"
        style="width: 250px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="sousuo()"
        ></el-button>
      </el-input>
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="clickadd()"
      >
        添加
      </el-button>
      <el-button type="success" @click="muban">导出模板</el-button>
      <el-button type="success" @click="downloads">导出表格</el-button>

      <el-upload
        class="upload-demo"
        action="https://www.hyzfjd.cn/api/sys/sysCountry/batchImport"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :on-success="correct"
        multiple
        :limit="999"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :headers="headers"
        :show-file-list="false"
      >
        <el-button style="margin-left: 10px" type="primary">点击上传</el-button>
      </el-upload>
    </div>
    <el-table
      v-if="tableHead.length"
      :data="
        tableDatas.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      "
      style="width: 100%"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <!-- <el-table-column
        v-for="(data, key) in tableHead"
        :prop="data"
        :label="data"
        :key="key"
      >
      </el-table-column> -->
      <el-table-column prop="countryName" label="乡镇名称"> </el-table-column>
      <el-table-column prop="countryContacts" label="联系人"> </el-table-column>
      <el-table-column prop="countryContactsPhone" label="联系人电话">
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleUpdate(scope.row)"
          >
            编辑
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="10"
        layout="sizes, prev, pager, next"
        :total="tableDatas.length"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="乡镇名称">
          <el-input v-model="formLabelAlign.countryName"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="formLabelAlign.countryContacts"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话">
          <el-input v-model="formLabelAlign.countryContactsPhone"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新建弹窗 -->
    <el-dialog title="新建乡镇" :visible.sync="dialogVisible2" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="乡镇名称">
          <el-input v-model="formLabelAlign.countryName"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="formLabelAlign.countryContacts"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话">
          <el-input v-model="formLabelAlign.countryContactsPhone"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="confirm2()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { read, utils } from "xlsx";
import JSZip from "jszip";
import FileSaver from "file-saver";
import {
  getsysCountrylist,
  PostsysCountrysave,
  DeletesysCountry,
  PutsysCountryupdate,
  PostbatchImport,
  GetexportCountry,
  GetexportTemplates,
} from "@/request/api";
Vue.use(ElementUI);

var token = localStorage.getItem("token");
export default {
  data() {
    return {
      id: "",
      fileList: [], //上传文件列表
      tableHead: ["企业名称", "编号", "所属乡", "二维码", "联系电话"], //表头
      tableData: [], // 表数据
      tableDatas: [], // 表数据
      //----------------------------
      dialogVisible: false, //编辑弹出框判定
      dialogVisible2: false, //新建弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        countryName: "",
        countryContacts: "",
        countryContactsPhone: "",
      },
      input: "",
      multipleSelection: [], //复选框组
      fileList: [],
      headers: { Authorization: token },
      currentPage: 1, //分页
      pageSize: 10,
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    correct(file, fileList) {
      this.seelist();
      this.$message({
        message: "上传成功",
        type: "success",
      });
    },

    //分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // 导出表格
    downloads() {
      GetexportCountry().then((res) => {
        const url = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(url);
        a.download = "乡镇.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    // 导出模板
    muban() {
      GetexportTemplates().then((res) => {
        const url = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(url);
        a.download = "乡镇模板.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    // 复选框组
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    //修改企业数据
    handleUpdate(v) {
      this.id = v.id;
      this.formLabelAlign.countryContacts = v.countryContacts;
      this.formLabelAlign.countryContactsPhone = v.countryContactsPhone;
      this.formLabelAlign.countryName = v.countryName;
      this.dialogVisible = true;
    },
    // 修改企业数据确认
    confirm() {
      PutsysCountryupdate({
        countryContacts: this.formLabelAlign.countryContacts,
        countryContactsPhone: this.formLabelAlign.countryContactsPhone,
        countryName: this.formLabelAlign.countryName,
        createTime: "",
        deleteFlag: "",
        id: this.id,
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code) {
          this.seelist();
          this.dialogVisible = false;
        }
      });
    },
    //添加企业数据
    clickadd() {
      this.formLabelAlign.countryContacts = "";
      this.formLabelAlign.countryContactsPhone = "";
      this.formLabelAlign.countryName = "";
      this.dialogVisible2 = true;
    },
    // 添加企业数据确认
    confirm2() {
      PostsysCountrysave({
        countryContacts: this.formLabelAlign.countryContacts,
        countryContactsPhone: this.formLabelAlign.countryContactsPhone,
        countryName: this.formLabelAlign.countryName,
        createTime: "",
        deleteFlag: "",
        id: "",
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.seelist();
          this.dialogVisible2 = false;
        }
      });
    },
    // 删除企业信息
    handleDelete(v) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeletesysCountry({ id: v.id }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 搜索乡镇列表
    sousuo() {
      getsysCountrylist({
        companyName: this.input,
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableDatas = res.data.list;
        }
      });
    },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.currentPage = val;
    },
    // 乡镇列表
    seelist() {
      getsysCountrylist({
        companyName: "",
        pageNum: 1,
        pageSize: 10000000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableDatas = res.data.list;
        }
      });
    },
  },
  mounted() {
    this.seelist(); //企业列表
  },
};
</script>
<style lang="scss" scoped>
.upload-demo {
  width: 100%;
}
.flex-display {
  margin: 10px 8px 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.el-upload {
  margin-left: 40px;
  .el-btn {
    font-size: 16px;
  }
  .el-upload-tip {
    display: inline;
    font-size: 12px;
  }
}
.file-ipt {
  width: 100px;
  height: 40px;
  line-height: 36px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  button {
    background-color: #409eff;
  }
}
input #file-upload-button {
  background-color: #409eff;
}

.el-upload__tip {
  margin-top: 20px;
  margin-left: 10px;
}
.block {
  text-align: center;
  margin-top: 15px;
}
</style>